<template>
  <div style="text-align: center;width: 100%;">
    <template v-if="apiLoading">
      <i class="fas fa-sync fa-spin fa-2x"></i>
    </template>
    <template v-else>
      <div
        v-for="(e, i) in selected"
        :key="e.id"
        style="display: flex;gap:1rem;"
      >
        <div class="form-group" style="flex: 1;">
          <b>Categorie achiziție</b>
          <v-select-infinite
            v-model="e.value"
            label="achizitionCategory"
            :startup="!readonly && i === 0"
            :apiModule="apiModule"
            :totalOptions="totalOptions"
            :totalRecordsCount="totalRecordsCount"
            :excludeClones="excludeClones"
            :no-drop="readonly"
            :clearable="!readonly"
            :disabled="readonly || !e.canEdit"
            @totalRecordsCount="e => (totalRecordsCount = e)"
          >
            <template #option="{ achizitionCategory }">
              <span class="v-select_li_custom">
                <span>{{ achizitionCategory || '' }}</span>
                <i
                  v-tooltip="achizitionCategory || ''"
                  class="fas fa-info-circle"
                ></i>
              </span>
            </template>
          </v-select-infinite>
        </div>
        <div class="form-group" style="flex: 1;">
          <b>Clasificație bugetare</b>
          <div v-tooltip="getBudgetLabel(e.value)">
            <input
              type="text"
              class="vInput form-disabled"
              :value="getBudgetLabel(e.value)"
              disabled
            />
          </div>
        </div>
        <div
          v-if="!readonly"
          v-show="e.value || i > 0"
          style="display: flex;align-items: center;justify-content: center;padding-top: 1rem;"
        >
          <i
            class="fas fa-times"
            style="font-size: 1.5em;cursor: pointer;"
            :style="(e.value ? '' : 'visibility: hidden;') + (e.canEdit ? '' : 'cursor: not-allowed;opacity: 0.6;')"
            v-tooltip="e.canEdit ? '' : 'Această categorie de achiziție nu poate fi eliminată.<br/>Aceasta face parte dintr-o propunere de nevoie.'"
            @click="e.canEdit ? e.value = null : false"
          ></i>
        </div>
      </div>
      <div v-if="!readonly" v-show="canAdd" style="text-align: right;">
        <div @click="addOption" class="Button1">
          <div class="icon">
            <img
              @load="imgLoaded"
              src="../assets/media/for_table/circle_ADD_new_etap.svg"
              alt=""
            />
          </div>
          <span class="Btn_content">
            Adaugă
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ACHIZITII } from '@/api.js'

export default {
  name: 'CatalogAchizitionsSelect',
  props: {
    value: {},
    initial: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      apiModule: ACHIZITII.get,
      achizition: this.value ?? null,
      totalOptions: [],
      totalRecordsCount: 0,
      selected: [],
      getSelectSchema() {
        return {
          value: null,
          id: typeof this.makeid == 'function' ? this.makeid(8) : Date.now(),
          excluded: false,
          canEdit: true
        }
      },
      apiLoading: false,
    }
  },
  computed: {
    canAdd() {
      const x = this.selected
      return !x.length || !!x.at(-1).value
    },
    oldSelected() {
      return this.deepClone(this.selected)
    },
    excludeClones() {
      return this.selected.filter(e => e.value?.id).map(e => e.value.id)
    },
  },
  methods: {
    getBudgetLabel(x) {
      return x?.budgetClasification?.name || ''
    },
    addOption() {
      if (!this.canAdd) return

      this.selected.push(this.getSelectSchema())
    },
  },
  watch: {
    initial: {
      handler(val) {
        if(!Array.isArray(val)) return

        for(let e of val) {
          if(Number.isInteger(e?.id)) {
            const schema = this.getSelectSchema()
            schema.value = e
            schema.canEdit = !!e.canBeRemoved
            schema.excluded = true

            this.selected.unshift(schema)
          }
        }
      },
      immediate: true
    }
  },
  created() {
    const startWatch = () => {
      this.apiLoading = false

      this.$watch('oldSelected', {
        handler(x, old) {
          if (!old) old = []

          if (!x.length) {
            this.addOption()
            this.$emit('input', [])
          } else {
            const y = [...x]
            let wasFiltered = false

            for (let i = 0; i < y.length; i++) {
              const e = y[i]
              const oldE = old[i]
              const restore = () => {
                if (e.excluded) {
                  const findData = oldE?.value

                  if (findData) {
                    this.totalOptions.splice(0, 0, findData)
                  }
                }
              }
              const exclude = () => {
                const index = this.totalOptions.findIndex(
                  o => o.id === e.value?.id
                )

                if (~index) {
                  this.selected[i].excluded = true

                  this.totalOptions.splice(index, 1)
                }
              }

              if (!e.value && e.excluded) {
                y.splice(i, 1)
                wasFiltered = true

                restore()
              } else if (!e.excluded && e.value?.id) {
                exclude()
              } else {
                const find = old.find(i => i.id == e.id)
                if(find && e.value?.id !== find.value?.id) {
                  restore()
                  exclude()
                }
              }
            }

            if (wasFiltered) this.selected = y

            const prepareEmit = []

            for (let e of y) {
              if (Number.isInteger(e.value?.id)) {
                prepareEmit.push(e.value.id)
              }
            }

            this.$emit('input', prepareEmit)
          }
        },
        deep: true,
        immediate: true,
      })
    }

    if (Array.isArray(this.value) && this.value.length) {
      this.apiLoading = true

      const promises = []

      for (let e of this.value) {
        if (Number.isInteger(e) && e > 0) {
          promises.push(ACHIZITII.getOne(e))
        }
      }

      Promise.all(promises)
        .then(res => {
          for (let e of res) {
            if (e?.data?.result) {
              const schema = this.getSelectSchema()
              schema.value = e.data.result
              schema.excluded = true

              this.selected.unshift(schema)
            }
          }

          this.$nextTick(startWatch)
        })
        .catch(startWatch)
    } else startWatch()
  },
}
</script>

<style></style>
